@use '../abstracts/index' as a;

.text-center {
  text-align: center;
}

.extra-mt-3 {
  margin-top: 3rem;
}

.extra-mt-1 {
  margin-top: 1.4rem;
}

.extra-mb-2 {
  margin-bottom: 2rem;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #222;
  color: a.$clr-black-600;
  padding: .65em 1.5em;
  font-size: 0.96rem;
}

.btn:hover {
  border: 1px solid a.$clr-secondary-400;
  background-color: a.$clr-secondary-400;
  color: #fff;
}

