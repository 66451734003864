@use '../abstracts/index' as a;

footer {
  padding: 3rem 0;
  background: a.$clr-dark;
  font-size: .8rem;
  text-align: center;
}

@media (min-width: 992px) {
  footer {
    text-align: left;
  }
}

footer * {
  color: #fff;
}

footer p {
  margin-bottom: 0;
}