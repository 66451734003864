@use '../abstracts/index' as a;

img {
  display: block;
  max-width: 100%;
}

header {
  position: fixed;
  background: #fff;
  margin: 0.75rem;
  box-shadow: 0 0 12px 0 rgba(0,0,0,.09);
  border-radius: 4px;
  width: calc(100% - 1.5rem);
  padding: 1rem 0;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 1000;
}

@media (min-width: 992px) {
  header {
    margin: 0;
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    padding: 1rem 0;
  }
}

.sticky {
  transition: all .3s cubic-bezier(0.55,0.085,0.68,0.53);
  box-shadow: 0 0 12px 0 rgba(0,0,0,.09);
}

@media (min-width: 992px) {
  .sticky {
    padding: 0.75rem 0;
  }
}

section {
  padding-bottom: 5rem;
}

@media (min-width: 992px) {
  section {
    padding-bottom: 6rem;
  }
}

.container {
  margin: 0 auto;
  width: 90%;
  max-width: 1320px;
}

.container--narrow {
  max-width: 34rem;
}

.split {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  .split {
    flex-direction: row;
  }
  
  .split > * {
    position: relative;
    flex-basis: 100%;
  }

  .split > * + * {
    margin-left: 3em;
  }

  .split-col-40 {
    flex-basis: 40%;
  }
}
