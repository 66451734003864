.intro {
  padding-top: 10.5rem;
}

.intro img {
  position: static;
  margin-top: 2rem;
  margin-left: -2rem;
  max-width: 110%;
}

@media (min-width: 1024px) {
  .intro img {
    position: absolute;
    top: -2rem;
    left: 0;
    margin-top: 0;
    margin-left: 0;
    width: auto;
    max-width: unset;
  }
}