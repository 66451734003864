@use '../abstracts/index' as a;

.cards {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.cards > * {
  margin-bottom: 2rem;
}

.cards a {
  text-decoration: none;
}

@media (min-width: 500px) {
  .cards {
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .cards > * {
    flex-basis: calc(50% - 1rem);
  }

  .card:nth-child(even) {
    margin-left: 2rem;
  }
}

@media (min-width: 1199px) {
  .cards {
    flex-wrap: nowrap;
  }
  
  .cards > * {
    flex-basis: 100%;
  }

  .cards > * + * {
    margin-left: 2rem;
  }
}

.card {
  position: relative;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  padding: 2rem 1.5rem;
  background-color: #fff;
  text-align: center;
}

.card img {
  margin: 0 auto;
}

.card-counter-number {
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: 700;
  color: a.$clr-secondary-400;
}

.card-counter-text {
  font-size: 0.9rem;
  color: a.$clr-secondary-400;
}

.card::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 30px 30px 0 0;
  border-color: transparent a.$clr-secondary-400 transparent transparent;
  width: 0;
  height: 0;
}