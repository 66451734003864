@use '../abstracts/index' as a;

html {
  font-size: 1rem;
}

@media (min-width: 992px) {
  html {
    font-size: 1.25rem;
  }
}

body {
  line-height: 1.6rem;
  font-family: a.$ff-primary;
  font-weight: 400;
  color: a.$clr-black-400;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

h1,
h2,
h3 {
  margin-bottom: 0.75em;
  line-height: 1;
  font-weight: 900;
  color: a.$clr-primary-400;
}

* + h2,
* + h3,
* + h4,
* + h5 {
  margin-top: 1.25em;
}


p {
  margin-bottom: 1em;
}


h1 {
  font-size: 2.441rem;
  color: a.$clr-black-600;
}

h1.h1-home {
  font-size: 2.441rem;
}

@media (min-width: 1199px) {
  h1.h1-home {
    font-size: 3.052rem;
  }
}


h2 {
  margin-bottom: 1.5em;
  font-size: 1.563rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  h2 {
    text-align: left;
  }
}
